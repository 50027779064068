import axios from 'axios'
const header_db = {}

function authenticate_header() {
  const jwt = window.the_vl_jwt || document.head.querySelector('meta[name="user-token"]')?.content
  // const bearer = document.head.querySelector('meta[name="gym-uuid"]')?.content || jwt // TODO [NQ-3000]: remove -> exclusive for finance
  const csrf = document.head.querySelector('meta[name="csrf-token"]')?.content

  return {
    headers: { Authorization: jwt ? `Bearer ${jwt}` : undefined, 'X-CSRF-TOKEN': csrf, ...header_db },
  }
}

export default {
  get(url, opts = {}) {
    const config = authenticate_header()
    config.params = opts.params

    return axios.get(url, config)
  },

  post(url, payload = {}) {
    const config = authenticate_header()

    return axios.post(url, payload, config)
  },

  put(url, payload = {}, opts = {}) {
    const config = authenticate_header()
    config.params = opts.params

    return axios.put(url, payload, config)
  },

  patch(url, payload = {}, opts = {}) {
    const config = authenticate_header()
    config.params = opts.params

    return axios.patch(url, payload, config)
  },

  delete(url, opts = {}) {
    const config = authenticate_header()
    config.params = opts.params
    config.data = opts.data

    return axios.delete(url, config)
  },

  add_header(key, value) {
    header_db[key] = value
  },
}
